<template>
    <div class="level_index level_indexI">

        <div class="search">
            <!-- <el-form :inline="true" :model="search">
        <el-form-item prop="title">
          <el-input v-model="search.title" placeholder="请输入关键字" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(data.size)">
            <i class="fa fa-search" aria-hidden="true"></i>
            查询
          </el-button>

          <el-button class="block" @click="del">批量删除</el-button>


        </el-form-item>
      </el-form> -->
        </div>

        <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border>

            <el-table-column show-overflow-tooltip prop="listName" label="表名" align="left" />
            <el-table-column show-overflow-tooltip prop="engine" label="引擎" align="left" />
            <el-table-column show-overflow-tooltip prop="edition" label="版本" align="left" />
            <el-table-column show-overflow-tooltip prop="code" label="编码" align="left" />
            <el-table-column show-overflow-tooltip prop="num" label="记录数" align="left" />
            <el-table-column show-overflow-tooltip prop="size" label="大小" align="left" />
            <el-table-column show-overflow-tooltip prop="index" label="自增索引" align="left" />
            <el-table-column show-overflow-tooltip prop="remarks" label="表备注" align="left" />
            <el-table-column show-overflow-tooltip prop="state" label="状态" align="left" width="90" />
            <el-table-column show-overflow-tooltip prop="createdtime" label="创建时间" align="left" />
            <el-table-column show-overflow-tooltip prop="updatetime" label="更新时间" align="left" />

        </Fanfu-Table>

    </div>
</template>

<script>
    import resizeDetector from 'element-resize-detector'


    const list = [{
            listName: "yf_action",
            engine: "InnoDB",
            edition: "10.0",
            code: "utf8mb4_general_ci",
            num: "1",
            size: "16384 ",
            index: "128",
            remarks: "系统行为表",
            state: "未备份 ",
            createdtime: "2022-7-8 16:22:26",
            updatetime: "2022-7-8 16:22:30",
        },

    ]
    export default {

        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: list.length
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList(size) {
                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)

                    setTimeout(() => {
                        this.modifyLayout();
                    }, 1100)


                })
            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },




            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }

                });

            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }

    }
</script>
<style>

</style>